
import React from "react"

import BrandeditorPage from "../../components/negosyonow/controls/brandeditor";


const BrandPage = ({location}) => {
	return <BrandeditorPage
				location={location}
				allowadd={true}
			/>
}


export default BrandPage;
